html,body {
    max-width: 960px;
    padding: 1em;
    margin: 0 auto;
    background: #1D2340;
    font-family: sans-serif;
}

@mixin message {
    display: flex;
    margin-bottom: 2em;
    white-space: pre-wrap;
}

.message-system {
    justify-content: center;
    color: lightgray;
    padding: 0 20px;
    font-size: 13px;
    @include message
}
.message-user {
    justify-content: flex-end;
    @include message
}

.message-assistant {
    justify-content: flex-start;
    @include message
}

@mixin bubble {
    padding: .5em .9em;
    max-width: 40em;
    border-radius: 8px;
    position: relative;
}

.bubble-user {
    background: #4F6EF8;
    color: #FFF;
    justify-content: flex-end;
    @include bubble;
}
.bubble-user::after {
    content: "";
    position: absolute;
    bottom: 0.5em;
    right: -0.8em;
    border-width: 0 1em 1em 0;
    border-style: solid;
    border-color: transparent #4F6EF8 transparent transparent;
    transform: rotate(180deg);
}

.bubble-assistant {
    background: #334155;
    color: #C8CCD3;
    justify-content: flex-start;
    @include bubble;
}
.bubble-assistant::after {
    content: "";
    position: absolute;
    top: 0.5em;
    left: -0.8em;
    border-width: 0 1em 1em 0;
    border-style: solid;
    border-color: transparent #334155 transparent transparent;
}